import React from "react"
import {graphql, Link} from "gatsby"
import Layout from "../components/layout"
import {NewsNode} from "../interfaces/news"
import NewsList from "../components/news/news-list"
import slugify from "../functions/slugify"
import {SEO} from "../components/seo"
import parse from "html-react-parser"

interface NewsList {
  data: {
    allDatoCmsNews: {
      nodes: NewsNode[]
    }
  }
  pageContext: {
    publicationType: string
    url: string
    title: string
    id: string
    menuName?: string
  }
}

const NewsListingTemplate = ({data, pageContext}: NewsList) => {
  const news = data.allDatoCmsNews.nodes
  return (
    <Layout currentLocation={pageContext.url}>
      <h1>{pageContext.title}</h1>
      <ul className="news main">
        <br />
        {news.slice(0, 3).map((news: NewsNode, index: number) => (
          <div key={index}>
            <li>
              <div className="date">
                <div className="day">{news.date.slice(0, 2)}</div>
                <div className="month">{news.date.slice(2, 6)}</div>
              </div>
              <div className="content">
                <Link to={"/nieuws/" + slugify(news.title)}>{news.title}</Link>
                <p>{parse(news.introduction)}</p>
              </div>
            </li>
          </div>
        ))}
      </ul>

      <h2>Oudere berichten</h2>
      <NewsList newsList={news.slice(3)} />
    </Layout>
  )
}

export const query = graphql`
  query ($publicationType: String) {
    allDatoCmsNews(
      filter: {publicationType: {eq: $publicationType}}
      sort: {fields: date, order: DESC}
    ) {
      nodes {
        title
        introduction
        date(formatString: "DD MMM YYYY")
      }
    }
  }
`

export default NewsListingTemplate

export const Head = ({pageContext}: NewsList) => {
  return <SEO title={pageContext.title} />
}
